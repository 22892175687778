<template>
  <div>
    <div class="front-notice"><i class="el-icon-bell" style="margin-right: 2px"></i>公告：公告</div>
    <!--头部-->
    <div class="front-header">
      <div class="front-header-left">
        <img src="@/assets/img/logo.jpg" alt="">
        <div class="title">学术不端文献检测站</div>
      </div>
      <div class="front-header-center">
        <div style="background-color: #eee;">
          <el-menu style="margin-left: 10px"
                   background-color="#eee"
                   :default-active="$route.path" mode="horizontal" router>
            <el-menu-item index="/check">提交检测</el-menu-item>
            <el-menu-item index="/queryreport">查询报告</el-menu-item>
            <el-menu-item index="/question">问题检测</el-menu-item>
            <el-menu-item index="/login-admin">管理员</el-menu-item>
          </el-menu>
        </div>
      </div>
      <div class="front-header-right">
      </div>
    </div>
    <div class="main-body" style="width: 80%;margin: 0 auto;">
      <div style="height: 20px;line-height: 20px;margin: 20px 0;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>
            <el-button type="text" size="mini"  @click="toPage('/check')">
              首页
            </el-button>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <el-button size="mini" type="text"  @click="toPage($route.path)">
              {{ $route.meta.name }}
            </el-button>
            </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <router-view ref="child"/>
    </div>
    <div style="width: 100%;background-color: #eee;height: 150px;margin-top: 10px;">
      <div style="display: flex;justify-content: center;">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Front",

  data() {
    return {}
  },

  mounted() {

  },
  methods: {
    toPage(path) {
      if (!path.startsWith('/')) {
        path = '/' + path;
      }
      window.location.href = path;
    }
  },
  beforeMount() {
    // if (this.$route.path === "/") {
    //   this.$router.replace("/check")
    // }
  }

}
</script>

<style scoped>
@import "@/assets/css/front.css";
</style>
