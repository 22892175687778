import Vue from 'vue'
import VueRouter from 'vue-router'
import Front from '@/views/Front.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Front',
    component: Front,
    redirect: '/check',
    children: [
      {path: '/check', name: 'Check', meta: {name: '提交检测'}, component: () => import('@/views/Check.vue')},
      {path: '/queryreport', name: 'QueryReport', meta: {name: '查询结果'},  component: () => import('@/views/QueryReport.vue')},
      {path: '/question', name: 'Question', meta: {name: '问题检测'},  component: () => import('@/views/Question.vue')},
      {path: '/login-admin', name: 'Admin', meta: {name: '管理员登录'},  component: () => import('@/views/login-admin.vue')},
      {path: '/platform-admin', name: 'Platform', meta: {name: '管理员页面'},  component: () => import('@/views/platform-admin.vue')}
    ]
  },
  {path: '*', name: '404', component: () => import('../views/404.vue')}
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫 如果用户来到/, 就跳转到/check
// router.beforeEach((to, from, next) => {
//   if (to.path === '/') {
//     next('/check')
//   } else if (to.path === '/check') { // 添加这个条件
//     next()
//   } else {
//     next()
//   }
// })

export default router
